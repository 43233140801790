import React from 'react'
import { Helmet } from 'react-helmet'

const Meta = ({ title, description, keywords, canonical }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name='description' content={description} />
      <meta name='keyword' content={keywords} />
      <link rel="canonical" href={canonical} />
    </Helmet>
  )
}

Meta.defaultProps = {
  title: 'Welcome To Sri Mahaveer Flowers',
  description: 'We sell the best decoration items',
  keywords: 'garland, toran, door set, mala',
  canonical: 'https://srimahaveerflowers.in'
}

export default Meta
