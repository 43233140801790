import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import Product from '../components/Product'
import Message from '../components/Message'
import Loader from '../components/Loader'
import Meta from '../components/Meta'

const CategoryScreen = ({ match }) => {
  const id = match.params.id

  const productList = useSelector((state) => state.productList)
  const { loading, error } = productList

  const products = productList.products ? productList.products.filter(x => x.category.toString() === id.toString()) : []

  return (
    <>
      <Meta
        title={id}
        canonical={`https://srimahaveerflowers.in/category/${id}`}
      />
      <Link className='btn btn-light my-3' to='/'>
        Go Back
      </Link>
      <h1 style={{ textAlign: 'center', textDecoration: 'underline' }}>Products</h1>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <Row>
          {products.map((product) => (
            <Col key={product.product_id} sm={12} md={6} lg={4} xl={3}>
              <Product product={product} />
            </Col>
          ))}
        </Row>
      )}
      <hr />
    </>
  )
}

export default CategoryScreen
