import React, { useState } from 'react';
import './ProductImages.css';

const ProductImages = ({ images = [{ url: '' }] }) => {
  const [main, setMain] = useState(images[0]);

  return (
    <>
      <img src={main.url} alt='main' className='main' loading='lazy' />
      <div className='product-gallery'>
        {images.map((image, index) => {
          return (
            <img
              src={image.url}
              alt={image.id}
              key={index}
              onClick={() => setMain(images[index])}
              className={`${image.url === main.url ? 'active' : null}`}
              loading='lazy'
            />
          );
        })}
      </div>
    </>
  );
};

export default ProductImages;
