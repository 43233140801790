import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import {
  listCategories,
  deleteCategory
} from '../actions/categoryActions'

const CategoryListScreen = ({ history }) => {
  const dispatch = useDispatch()

  const categoryList = useSelector((state) => state.categoryList)
  const { loading, error, categories } = categoryList

  const categoryDelete = useSelector((state) => state.categoryDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = categoryDelete

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (!userInfo) {
      history.push('/login')
    }

    dispatch(listCategories())
  }, [
    dispatch,
    history,
    userInfo,
    successDelete
  ])

  const deleteHandler = (id) => {
    if (window.confirm('Are you sure')) {
      dispatch(deleteCategory(id))
    }
  }

  return (
    <>
      <Row className='align-items-center'>
        <Col>
          <h1>Category</h1>
        </Col>
        <Col className='text-right'>
          <Link to='/admin/category/create' className='my-3'>
            <i className='fas fa-plus'></i> Create Category
          </Link>
        </Col>
      </Row>
      {loadingDelete && <Loader />}
      {errorDelete && <Message variant='danger'>{errorDelete}</Message>}
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <Table striped bordered hover responsive className='table-sm'>
            <thead>
              <tr>
                <th>ID</th>
                <th>NAME</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {categories.length > 0 ? categories.map((item) => (
                <tr key={item.category_id}>
                  <td>{item.category_id}</td>
                  <td>{item.name}</td>
                  <td>
                    <LinkContainer to={`/admin/category/${item.category_id}/edit`}>
                      <Button variant='light' className='btn-sm'>
                        <i className='fas fa-edit'></i>
                      </Button>
                    </LinkContainer>
                    <Button
                      variant='danger'
                      className='btn-sm'
                      onClick={() => deleteHandler(item.category_id)}
                    >
                      <i className='fas fa-trash'></i>
                    </Button>
                  </td>
                </tr>
              )):(
                <>
                No Categories found
                </>
              )}
            </tbody>
          </Table>
        </>
      )}
    </>
  )
}

export default CategoryListScreen
