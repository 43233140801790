import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import Category from '../components/Category'
import Message from '../components/Message'
import Loader from '../components/Loader'
import Meta from '../components/Meta'
import { FaWhatsapp } from 'react-icons/fa'
import { AiOutlineMail } from 'react-icons/ai'

const HomeScreen = ({ match }) => {
  const keyword = match.params.keyword

  const categoryList = useSelector((state) => state.categoryList)
  const { loading, error, categories = [] } = categoryList

  return (
    <>
      <Meta />
      {keyword && (
        <Link to='/' className='btn btn-light'>
          Go Back
        </Link>
      )}

      <h1 style={{ textAlign: 'center', textDecoration: 'underline' }}>Categories</h1>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <Row>
          {categories.map((category) => (
            <Col key={category.category_id} sm={12} md={6} lg={4} xl={3}>
              <Category category={category} />
            </Col>
          ))}
        </Row>
      )}
      <hr />
      <div>
        <h2 style={{ textAlign: 'center', textDecoration: 'underline' }}>meet us</h2>
        <div style={{ textAlign: 'center' }}>
          <iframe
            title="map"
            id="gmap_canvas"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d243.00428751750914!2d77.57785748992335!3d12.967462122680976!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1737617dadcb%3A0x9a1c7bfd37a14582!2sSri%20Mahaveer%20Flowers!5e0!3m2!1sen!2sin!4v1670931316275!5m2!1sen!2sin"
            frameBorder="0"
            scrolling="no"
            marginHeight="0"
            marginWidth="0"
            width="90%"
            height="300rem"
            loading="lazy">
          </iframe>
        </div>
        <br />
        <div>
          <div>
            <h4 style={{ textAlign: 'center', textDecoration: 'underline' }}>contact us via</h4>
          </div>
          <ul style={{ listStyleType: 'none', paddingTop: '1rem', fontSize: 'larger' }}>
            <li>
              <FaWhatsapp />
              <a href="https://wa.me/9742072465/" target="_blank" rel="noopener noreferrer"> (91) 9742072465</a>
            </li>
            <li>
              <AiOutlineMail />&nbsp;<a href="mailto:info@srimahaveerflowers.in" target="_blank" rel="noopener noreferrer">info@srimahaveerflowers.in</a>
            </li>
          </ul>
        </div>
      </div>
      <hr />
    </>
  )
}

export default HomeScreen
