import axios from 'axios'
import { v4 } from 'uuid'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import { updateProduct, createProduct } from '../actions/productActions'
import { PRODUCT_UPDATE_RESET, PRODUCT_CREATE_RESET } from '../constants/productConstants'

const ProductEditScreen = ({ match, history }) => {
  const productId = match.params.id

  const [name, setName] = useState('')
  const [code, setCode] = useState('')
  const [image, setImage] = useState([])
  const [category, setCategory] = useState('')
  const [description, setDescription] = useState('')
  const [uploading, setUploading] = useState(false)

  const dispatch = useDispatch()

  const productList = useSelector((state) => state.productList)
  const { loading, error, products = [] } = productList

  const product = productId ? products.find(x => x.product_id === productId) : {}

  const categoryList = useSelector((state) => state.categoryList)
  const { categories = [] } = categoryList

  const productUpdate = useSelector((state) => state.productUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = productUpdate

  const productCreate = useSelector((state) => state.productCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    product: createdProduct,
  } = productCreate

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (!userInfo) {
      history.push('/login')
    }

    if (successCreate) {
      dispatch({ type: PRODUCT_CREATE_RESET })
      history.push(`/admin/productlist`)
    } else if (successUpdate) {
      dispatch({ type: PRODUCT_UPDATE_RESET })
      history.push('/admin/productlist')
    } else {
      if (!!product && !!product.product_id) {
        setName(product.name)
        setCode(product.product_id)
        setImage(product.image)
        setCategory(product.category)
        setDescription(product.description)
      }
    }
  }, [dispatch, history, productId, product, successUpdate, createdProduct, successCreate, userInfo])

  const uploadFileHandler = async (e) => {
    const filesToUpload = { ...e.target.files }
    let uploadData = [...image]
    setUploading(true)
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    }
    try {

      for (const file in filesToUpload) {
        const formData = new FormData()
        formData.append('file', filesToUpload[file])
        formData.append('upload_preset', 'lo086of6')

        const { data } = await axios.post('https://api.cloudinary.com/v1_1/dupeaisq7/upload', formData, config)

        uploadData.push({ id: v4(), url: data.secure_url })
      }
    } catch (error) {
      console.error(error)
      setUploading(false)
    }
    setImage(uploadData)
    setUploading(false)
  }

  const deleteFile = (itemId) => setImage(image.filter(item=> item.id !== itemId))

  const submitHandler = (e) => {
    e.preventDefault()
    if (productId) {
      dispatch(
        updateProduct({
          product_id: productId,
          name,
          image,
          category,
          description
        })
      )
    } else {
      dispatch(
        createProduct({
          product_id: code,
          name,
          image,
          category,
          description
        })
      )
    }
  }

  return (
    <>
      <Link to='/admin/productlist' className='btn btn-light my-3'>
        Go Back
      </Link>
      <FormContainer>
        <h1>{productId ? 'Edit' : 'Create'} Product</h1>
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
        {loadingCreate && <Loader />}
        {errorCreate && <Message variant='danger'>{errorCreate}</Message>}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Form.Group controlId='code'>
              <Form.Label>Product Code</Form.Label>
              <Form.Control
                type='code'
                placeholder='Enter code'
                value={code}
                disabled={productId}
                onChange={(e) => setCode(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='name'>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type='name'
                placeholder='Enter name'
                value={name}
                onChange={(e) => setName(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='images'>
              <Form.Label>Images</Form.Label>
              <div className='gallery'>
                {image.map((imageItem, index) => {
                  return (
                    <div className="image-area" key={index}>
                    <img
                      src={imageItem.url}
                      alt={imageItem.id}
                      loading='lazy'
                    />
                    <input type="button" className="remove-image" style={{display: 'inline'}} onClick={()=>deleteFile(imageItem.id)} value="&#215;"></input>
                    </div>
                  );
                })}
              </div>
              <Form.File
                id='image-file'
                label='Choose File'
                custom
                onChange={uploadFileHandler}
                multiple
              ></Form.File>
              {uploading && <Loader />}
            </Form.Group>

            <Form.Group controlId='category'>
              <Form.Label>Category</Form.Label>
              <Form.Control as='select' onChange={(e) => setCategory(e.target.value)} value={category}>
                <option className="d-none" value=""> Select Option </option>
                <>
                  ({
                    categories.map(x => (
                      <option key={x.category_id} value={x.category_id}>{x.name}</option>
                    ))
                  })
                </>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId='description'>
              <Form.Label>Description</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter description'
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Button type='submit' variant='primary' disabled={!(category && name && image && code)}>
              {productId ? 'Update' : 'Create'}
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  )
}

export default ProductEditScreen
