import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <footer style={{
      backgroundColor: '#55595c'
    }}>
      <Container style={{
        color: '#ffffff'
      }}>
        <Row>
          <Col className='text-center py-3'>Copyright &copy; SriMahaveerFlowers</Col>
        </Row>
        <Row>
          <Col className='text-center' >
            <Link to='/privacy' style={{
              color: 'white'
            }}>
              Privacy Policy
            </Link>
          </Col>
          <Col className='text-center link-primary'>
            <Link to='/legal/terms-of-use' style={{
              color: 'white'
            }}>
              Terms & Conditions
            </Link>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
