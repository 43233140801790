import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import Header from './components/Header'
import Footer from './components/Footer'
import HomeScreen from './screens/HomeScreen'
import CategoryScreen from './screens/CategoryScreen'
import ProductScreen from './screens/ProductScreen'
import LoginScreen from './screens/LoginScreen'
import ProductListScreen from './screens/ProductListScreen'
import ProductEditScreen from './screens/ProductEditScreen'
import CategoryEditScreen from './screens/CategoryEditScreen'
import CategoryListScreen from './screens/CategoryListScreen'
import CartScreen from './screens/CartScreen'
import { listProducts } from './actions/productActions'
import { listCategories } from './actions/categoryActions'
import AboutUsScreen from './screens/AboutUsScreen'
import ContactUsScreen from './screens/ContactUsScreen'
import PrivacyScreen from './screens/PrivacyScreen'
import DisclaimerScreen from './screens/DisclaimerScreen'

const App = () => {
  const dispatch = useDispatch()

  useEffect(() => {
      dispatch(listCategories())
      dispatch(listProducts())
  }, [dispatch])

  return (
    <Router>
      <Header />
      <main className='py-3'>
        <Container>
          <Route path='/about' component={AboutUsScreen} />
          <Route path='/contact-us' component={ContactUsScreen} />
          <Route path='/privacy' component={PrivacyScreen} />
          <Route path='/legal/terms-of-use' component={DisclaimerScreen} />
          <Route path='/login' component={LoginScreen} />
          <Route path='/product/:id' component={ProductScreen} />
          <Route path='/category/:id' component={CategoryScreen} />
          <Route path='/cart/:id?' component={CartScreen} />
          <Route
            path='/admin/productlist'
            component={ProductListScreen}
            exact
          />
          <Route
            path='/admin/categoryList'
            component={CategoryListScreen}
            exact
          />
          <Route path='/admin/product/create' component={ProductEditScreen} />
          <Route path='/admin/product/:id/edit' component={ProductEditScreen} />
          <Route path='/admin/category/create' component={CategoryEditScreen} />
          <Route path='/admin/category/:id/edit' component={CategoryEditScreen} />
          <Route path='/search/:keyword' component={HomeScreen} exact />
          <Route path='/' component={HomeScreen} exact />
        </Container>
      </main>
      <Footer />
    </Router>
  )
}

export default App
