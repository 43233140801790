import React from 'react'
import { Link } from 'react-router-dom'
import Meta from '../components/Meta'

const AboutUsScreen = () => {
  return (
    <>
      <Meta 
        title={'About Us'}
        canonical={"https://srimahaveerflowers.in/about"}
      />
      <Link to='/' className='btn btn-light'>
        Go Back
      </Link>

      <h1 style={{ textAlign: 'center', textDecoration: 'underline' }}>About Us</h1>

      <hr />
      <div>
        <article>
          <div className="title">
            <h1>our story</h1>
          </div>
          <p>
            Welcome to our website for artificial flowers! We are committed to supplying high-quality, gorgeous flowers to complement any setting.
          </p>
          <p>
            Our team is deeply committed to bringing nature indoors and making it available to everyone, regardless of location or lifestyle. We think that artificial flowers may provide the same aesthetic appeal and mood as genuine flowers without the bother and upkeep that live plants require.
          </p>
          <p>
            We obtain our items from the most recognized manufacturers (except for a few which we manufacturer ourselves) and designers, guaranteeing that each bloom is handcrafted with care and dedication to quality. Our collection includes everything from classic and timeless items to current and fashionable arrangements, helping our clients to discover the right fit for their personal style and preferences.
          </p>
          <p>
            We value customer happiness and endeavor to create a seamless buying experience on our artificial flower website. Our website is user-friendly and simple to use, with thorough product descriptions and photographs that highlight the distinctive characteristics of each flower. We also provide dependable shipping and a no-hassle return policy, allowing our clients to order with confidence.
          </p>
          <p>
            Thank you for taking the time to look at our artificial flower website for your floral requirements. With our wonderful variety of flowers, we strive to provide a touch of beauty and elegance to your home or business.
          </p>
        </article>
      </div>
      <hr />
    </>
  )
}

export default AboutUsScreen
